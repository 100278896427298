import PaymentsTemplate from "assets/payment_upload_template.2025.01.13.zip";
import { Icon, Text, UploadWindow } from "components";
import { MAX_FILE_SIZE } from "components/UploadWindow";
import React from "react";
import { useHistory } from "react-router-dom";
import { fileUpload } from "store/actions/files";
import { useShallowSelector } from "store/hooks";
import { BaseStatus } from "store/reducers/standardReducer";
import { UploadType } from "@trolley/common-frontend";

interface Props {
  visible: boolean;
  onClose(): void;
}

export default function AddPaymentsPopup(props: Props) {
  const { visible, onClose } = props;
  const history = useHistory();
  const paymentBatchUploadStatus = useShallowSelector((state) => state.files.fetchStatus.data);

  async function onUpload(file: FormData) {
    const id = await fileUpload(UploadType.BATCH, file);
    onClose();
    history.push(`/payments/upload/${id}`);
  }

  return (
    <UploadWindow
      title="Upload payments"
      onClose={onClose}
      onSubmit={onUpload}
      loading={paymentBatchUploadStatus === BaseStatus.LOADING}
      visible={visible}
      maxSize={MAX_FILE_SIZE}
    >
      <Text padded align="center">
        <p>
          <a href={PaymentsTemplate}>Download Payment CSV Template</a>
          <Text type="secondary" size="small">
            <Icon type="circle-info" left color="blue" />
            Updated on December 12, 2022
          </Text>
        </p>
        <p>Please download the sample CSV file and re-upload with all the payments information you wish to add.</p>
        Maximum upload file size: {MAX_FILE_SIZE} MB.
      </Text>
    </UploadWindow>
  );
}
