/* istanbul ignore file */
import { storageFactory } from "utils/factories";

const AUTH_TOKEN = "auth-token";
const STATE_TOKEN = "auth-state-token";
const storage = storageFactory(() => sessionStorage);

export default {
  clear() {
    storage.clear();
  },
  set(value: string) {
    if (value === undefined) {
      storage.removeItem(AUTH_TOKEN);
      storage.removeItem(STATE_TOKEN);
    } else {
      storage.setItem(AUTH_TOKEN, value);
    }
  },
  setState(sandbox: boolean) {
    storage.setItem(STATE_TOKEN, sandbox ? "true" : "false");
  },
  get() {
    return storage.getItem(AUTH_TOKEN);
  },
  sandbox() {
    return storage.getItem(STATE_TOKEN) === "true" ? true : false;
  },
};
