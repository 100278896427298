import { default as zxcvbn } from "zxcvbn";

export enum PasswordStrength {
  WEAK = "weak",
  STRONG = "strong",
}

// This function follows the logic used in the API in the file src/lib/utils/passwords.ts
export function checkPasswordStrength(password: string) {
  if (!password) return PasswordStrength.WEAK;

  const result = zxcvbn(password);

  if (result.score < 2) {
    return PasswordStrength.WEAK;
  }

  const crackingTime = Number(result.crack_times_seconds.online_no_throttling_10_per_second);
  const crackingTimeThreshold = 100000000; // THREE_YEARS_IN_SECONDS

  if (crackingTime < crackingTimeThreshold) {
    return PasswordStrength.WEAK;
  }

  return PasswordStrength.STRONG;
}
