import { Access, CountryCode } from "@trolley/common-frontend";
import { Layout, Result } from "antd";
import { ButtonLink, Container, Divider, FormInstance, Grid, Heading, Icon, Space, Status, Text, ExternalLink } from "components";
import { BUSINESS_ONBOARDING_PATH } from "pages/BusinessProfile";
import React, { ReactNode, useLayoutEffect } from "react";
import Helmet from "react-helmet";
import { Route, Switch, useHistory } from "react-router-dom";
import { notifySuccess } from "store/actions/notifications";
import { Onboarding, OnboardingUpdate, updateOnboarding } from "store/actions/onboarding";
import { OnboardingPerson, OnboardingPersonUpdate, updateOnboardingPerson } from "store/actions/onboardingPersons";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useOnboarding } from "store/hooks/onboarding";
import { useAccess } from "store/hooks/user";
import colors from "style/colors";
import { asyncDelay, handleFormErrors, omitMaskedValues, pickDiff } from "utils/helpers";
import { useWindowSize } from "utils/hooks";
import BankOnboardingSteps from "./BankOnboardingSteps";
import BenefinialOwners from "./BenefinialOwners";
import OwnershipStructure from "./OwnershipStructure";
import ReviewCompletion from "./ReviewCompletion";
import ShareRegister, { getShareRegisters } from "./ShareRegister";
import SigningOfficer from "./SigningOfficer";
import { isPersonComplete } from "./variables";
import dayjs from "dayjs";

export const BANK_ONBOARDING_PATH = "/activate/bank";

export enum BankOnboardingPages {
  OWNERSHIP_STRUCTURE = "ownership-structure",
  SIGNING_OFFICERS = "signing-officers",
  BENEFICIAL_OWNERS = "beneficial-owners",
  SHARE_REGISTER = "share-register",
  REVIEW = "review",
}

export function onFinishOnboarding(record: Onboarding | OnboardingPerson | undefined, form: FormInstance) {
  return async (values: Partial<Onboarding | OnboardingPerson>) => {
    if (record) {
      const uploadData = pickDiff(omitMaskedValues(values), record);
      if (uploadData) {
        if ("businessCountry" in record) {
          try {
            await updateOnboarding(uploadData as OnboardingUpdate);
          } catch (error) {
            handleFormErrors(error, form, "Saving failed");
            throw error;
          }
          await asyncDelay(200); // allow dispatch to resolve
        } else {
          try {
            await updateOnboardingPerson(record.id, uploadData as OnboardingPersonUpdate);
            notifySuccess(record.isDirector ? "Director information saved" : "Owner information Saved");
          } catch (errors) {
            handleFormErrors(errors, form, "Saving failed");
            throw errors;
          }
        }
      }
    }
  };
}

export default function BankTransferOnboarding() {
  const history = useHistory();
  const { isMobile } = useWindowSize();
  const { data: onboarding } = useOnboarding();
  const { data: merchantSettings, isActive } = useMerchantSettings();
  const canEdit = useAccess(Access.ONBOARDING_WRITE);

  useLayoutEffect(() => {
    if (merchantSettings?.sandbox) {
      history.replace("/");
    } else if (merchantSettings && !isActive) {
      history.replace(BUSINESS_ONBOARDING_PATH);
    }
  }, [merchantSettings?.id]);

  useLayoutEffect(() => {
    if (onboarding) {
      if (onboarding.bankOnboardingCompletedAt) {
        history.replace([BANK_ONBOARDING_PATH, BankOnboardingPages.REVIEW].join("/"));
      } else {
        const directors = onboarding.persons.filter((p) => p.isDirector);
        const owners = onboarding.persons.filter((p) => p.isOwner);

        if (directors.every((person) => !isPersonComplete(person, "directors")) && !getShareRegisters(onboarding).length) {
          history.replace(BANK_ONBOARDING_PATH);
        } else if (directors.some((person) => !isPersonComplete(person, "directors"))) {
          history.replace([BANK_ONBOARDING_PATH, BankOnboardingPages.SIGNING_OFFICERS].join("/"));
        } else if (!onboarding.noOwnership && (!owners.length || owners.some((person) => !isPersonComplete(person, "owners")))) {
          history.replace([BANK_ONBOARDING_PATH, BankOnboardingPages.BENEFICIAL_OWNERS].join("/"));
        } else if (!getShareRegisters(onboarding).length) {
          history.replace([BANK_ONBOARDING_PATH, BankOnboardingPages.SHARE_REGISTER].join("/"));
        } else {
          history.replace([BANK_ONBOARDING_PATH, BankOnboardingPages.REVIEW].join("/"));
        }
      }
    }
  }, [typeof onboarding]); // only on first load

  if (!canEdit) {
    return (
      <Result
        icon={<Icon type="do-not-enter" theme="solid" color="red" size="4x" />}
        title="Access Restricted"
        subTitle="Only owners and admins have access to this section."
        extra={
          <ButtonLink type="primary" size="large" ghost path="/settings/payout-methods">
            Return to Payout Methods
          </ButtonLink>
        }
      />
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", backgroundColor: colors.white, minHeight: "100vh" }}>
      <div style={{ paddingBottom: "80px" }}>
        <Helmet>
          <title>Bank Transfer Activation</title>
        </Helmet>
        <Layout.Header style={{ padding: "0 8px", backgroundColor: "transparent" }}>
          <Space gap="middle">
            <ButtonLink
              path="/settings/payout-methods"
              icon={<Icon type="angle-left" />}
              type="text"
              tooltipProps={{ title: "Back to Payout Method", placement: "topLeft" }}
            />
            <strong>
              Bank Transfer Activation
              <Status type={onboarding?.bankOnboardingCompletedAt ? "completed" : "in_progress"} right />
            </strong>
          </Space>
        </Layout.Header>
        <Divider />
        <Container maxWidth="lg" padding="large">
          <Grid padding="large" justify="space-between" wrap={isMobile}>
            <Grid.Item flex={isMobile ? "100%" : 1} style={{ maxWidth: isMobile ? "100%" : "240px" }} alignSelf="flex-start">
              <BankOnboardingSteps />
            </Grid.Item>
            <Grid.Item flex={isMobile ? "100%" : 1} style={{ maxWidth: isMobile ? "100%" : "450px" }}>
              <Switch>
                <Route path={[BANK_ONBOARDING_PATH, BankOnboardingPages.SIGNING_OFFICERS].join("/")} component={SigningOfficer} />
                <Route path={[BANK_ONBOARDING_PATH, BankOnboardingPages.BENEFICIAL_OWNERS].join("/")} component={BenefinialOwners} />
                <Route path={[BANK_ONBOARDING_PATH, BankOnboardingPages.SHARE_REGISTER].join("/")} component={ShareRegister} />
                <Route path={[BANK_ONBOARDING_PATH, BankOnboardingPages.REVIEW].join("/")} component={ReviewCompletion} />
                <Route component={OwnershipStructure} />
              </Switch>
            </Grid.Item>
          </Grid>
        </Container>
      </div>
      {onboarding && onboarding.businessCountry === CountryCode.US && onboarding.expectedPayoutCountries.every((code) => code === CountryCode.US) && (
        <Container style={{ marginTop: "auto", padding: "16px" }}>
          <Text type="secondary" size="small">
            Copyright &copy; Trolley {dayjs().year()}.&nbsp;
            <ExternalLink href="https://www.trolley.com/legal-agreements">
              <Text type="secondary" inline weight="bold">
                Legal Agreements
              </Text>
            </ExternalLink>
            .&nbsp;For Trolley US Inc. customers, deposits held at Cross River Bank, Member FDIC, are insured up to $250,000.
          </Text>
        </Container>
      )}
    </div>
  );
}

export function OnboardingSection(props: { title: ReactNode; description?: ReactNode; children: ReactNode }) {
  return (
    <Container padding="none">
      <Heading tag="h2" description={props.description}>
        {props.title}
      </Heading>
      {props.children}
    </Container>
  );
}
