import { Badge, Button, Checkbox, Divider, Form, Grid, Heading, Icon, Input, InputPhone, SelectCountry, Text, phoneNumberValidator } from "components";
import config from "config";
import Cookie from "js-cookie";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { POST } from "services/request";
import { gTagCapture, gTagInit } from "services/thirdParty/google";
import { RegistrationParams, authLogin, authRegister } from "store/actions/auth";
import { notifyError } from "store/actions/notifications";
import { useShallowSelector } from "store/hooks";
import { BaseStatus } from "store/reducers/standardReducer";
import { handleFormErrors, parseQuery } from "utils/helpers";
import validator from "validator";
import FullscreenAuth from "./FullscreenAuth";
import { Partners } from "./FullscreenAuth/PartnerIcon";
import PasswordField from "./PasswordField";
import { useIntl } from "utils/context";
import { translateErrorMessages } from "./LoginPage";
import { getPublicPath, PublicModulesEnum, TermsOfServiceLink } from "pages/LoginNotRequired";
import { useLocale } from "store/hooks/locale";
import { SupportedLocales } from "@trolley/common-frontend";

/**
 * send partner codes as signupcode and hide field
 */
export const partnerCodes: Record<Partners, string> = {
  hasOffers: "hasoffers77",
  linkTrust: "linktrust21",
  plunet: "plunet22",
  amplifinity: "amplifinity44",
  paladin: "paladin70",
  refersion: "refersion55",
  dosandco: "dosandco55",
  tapfiliate: "tapfiliate22",
};

type FormFields = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  company: string;
  website: string;
  phone: string;
  country: string;
  code?: string;
  terms: boolean;
  consent?: boolean;
};

let domainSet: undefined | Set<string>;

export default function RegisterPage() {
  const location = useLocation();
  const locale = useLocale();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm<FormFields>();
  const userStatus = useShallowSelector((state) => state.user.fetchStatus.data);
  const [verificationEmailSent, setVerificationEmailSent] = useState<string | undefined>();
  const { partner, signupCode } = parseQuery(location.search) as { partner?: string; signupCode?: string };
  const [consentRequired, setConsentRequired] = useState(false);

  useEffect(() => {
    gTagInit();
    if (!domainSet) {
      import("./blockedDomains.json")
        .then((data) => {
          domainSet = new Set(data.default);
        })
        .catch(() => {
          // empty
        });
    }

    POST<{ showConsent: boolean }>("/v1/address/show-consent")
      .then(({ body }) => {
        setConsentRequired(!!body.showConsent);
      })
      .catch((errors) => {
        // show consent checkbox when there's an error
        setConsentRequired(true);
      });
  }, []);

  async function onFinish({ consent, ...values }: FormFields) {
    const update = { ...values } as RegistrationParams;

    if (config.PARDOT_ACCOUNT_ID) {
      const pardotVisitorId = Cookie.get(`visitor_id${config.PARDOT_ACCOUNT_ID}`);
      update.pardotVisitorId = pardotVisitorId; // DEPRECATED
      update.meta = {
        pardotVisitorId,
        utmSource: Cookie.get("utm_source"),
        utmMedium: Cookie.get("utm_medium"),
        utmCampaign: Cookie.get("utm_campaign"),
        utmTerm: Cookie.get("utm_term"),
        gclid: Cookie.get("gclid"),
        conversionPage: document.referrer,
        formName: "Account Signup - PLG",
        comments: "NEW TRIGGER",
        consent: consentRequired ? (consent ? "ON" : "") : "Not Applicable",
        // conversionIP: "", // API will submit it to Pardot. browser doesn't have visiblity on user's IP
      };
      if (locale === SupportedLocales.FR) {
        update.language = locale;
      }
    }

    try {
      await authRegister(update);
      gTagCapture();

      try {
        const loginAttempt = await authLogin({
          email: update.email,
          password: update.password,
        });

        if (loginAttempt.status !== "token") {
          // this shouldn't happen. As a fallback, we show a message.
          setVerificationEmailSent(update.email);
        }
      } catch (errors) {
        notifyError(formatMessage({ id: "auth.register.unableLogin" }), { errors });
      }
    } catch (errors) {
      handleFormErrors(translateErrorMessages(errors, formatMessage), form);
    }
  }

  return (
    <FullscreenAuth size="large" partner={partner}>
      {verificationEmailSent ? (
        <>
          <Text align="center" style={{ margin: "24px" }}>
            <Badge count={<Icon.Status type="success" style={{ top: "4px", right: "4px" }} />}>
              <Icon type="envelope" size="3x" color="grey" />
            </Badge>
          </Text>
          <Text align="center" size="large">
            {formatMessage({ id: "auth.register.verificationSent" }, { verificationEmail: verificationEmailSent })}
          </Text>
          <Divider transparent margin="small" />
          <Text type="secondary" align="center">
            <Link to={getPublicPath(PublicModulesEnum.Login, locale)}>
              <Icon type="angle-left" left />
              {formatMessage({ id: "auth.register.backToLogin" })}
            </Link>
          </Text>
        </>
      ) : (
        <>
          <Heading>{formatMessage({ id: "auth.register.title" })}</Heading>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{ code: signupCode || (partner && partnerCodes[partner]) }}
            requiredMark="optional"
            validateTrigger="onSubmit"
            data-testid="form"
          >
            <Grid padding={["medium", "none"]}>
              <Grid.Item xs={24} md={12}>
                <Form.Item<FormFields["email"]>
                  label={formatMessage({ id: "auth.register.fields.email.label" })}
                  name="email"
                  rules={[
                    { required: true, message: formatMessage({ id: "auth.register.fields.email.required" }) },
                    { type: "email", message: formatMessage({ id: "auth.register.fields.email.valid" }) },
                    {
                      async validator(rule, value) {
                        const domain = (value || "").split("@")[1];
                        if (domain && domainSet?.has(domain)) {
                          throw formatMessage({ id: "auth.register.fields.email.pleaseProvide" });
                        }
                      },
                    },
                  ]}
                >
                  <Input type="email" id="email" name="email" placeholder={formatMessage({ id: "auth.register.fields.email.placeholder" })} />
                </Form.Item>
              </Grid.Item>

              <Grid.Item xs={24} md={12}>
                <Form.Item
                  label={formatMessage({ id: "auth.register.fields.companyName.label" })}
                  name="company"
                  rules={[{ required: true, message: formatMessage({ id: "auth.register.fields.companyName.required" }) }]}
                >
                  <Input />
                </Form.Item>
              </Grid.Item>

              <Grid.Item xs={24} md={12}>
                <Form.Item
                  label={formatMessage({ id: "auth.register.fields.firstName.label" })}
                  name="firstName"
                  rules={[{ required: true, message: formatMessage({ id: "auth.register.fields.firstName.required" }) }]}
                >
                  <Input name="firstName" />
                </Form.Item>
              </Grid.Item>
              <Grid.Item xs={24} md={12}>
                <Form.Item
                  label={formatMessage({ id: "auth.register.fields.lastName.label" })}
                  name="lastName"
                  rules={[{ required: true, message: formatMessage({ id: "auth.register.fields.lastName.required" }) }]}
                >
                  <Input name="lastName" />
                </Form.Item>
              </Grid.Item>

              <Grid.Item xs={24} md={12}>
                <Form.Item
                  label={formatMessage({ id: "auth.register.fields.website.label" })}
                  name="website"
                  rules={[
                    { required: true, message: formatMessage({ id: "auth.register.fields.website.required" }) },
                    {
                      async validator(rule, value: string) {
                        if (!validator.isURL(value || "")) {
                          throw formatMessage({ id: "auth.register.fields.website.valid" });
                        }
                      },
                    },
                  ]}
                >
                  <Input id="website" name="website" placeholder={formatMessage({ id: "auth.register.fields.website.placeholder" })} />
                </Form.Item>
              </Grid.Item>

              <Grid.Item xs={24} md={12}>
                <Form.Item
                  label={formatMessage({ id: "auth.register.fields.companyPhone.label" })}
                  name="phone"
                  rules={[
                    { required: true, message: formatMessage({ id: "auth.register.fields.companyPhone.required" }) },
                    phoneNumberValidator([], {
                      invalidCountry: formatMessage({ id: "auth.register.fields.companyPhone.validator.invalid_country" }),
                      tooShort: formatMessage({ id: "auth.register.fields.companyPhone.validator.too_short" }),
                      tooLong: formatMessage({ id: "auth.register.fields.companyPhone.validator.too_long" }),
                      invalidPhone: formatMessage({ id: "auth.register.fields.companyPhone.validator.invalid_phone" }),
                      supportedCountry: formatMessage({ id: "auth.register.fields.companyPhone.validator.supported_country" }),
                    }),
                  ]}
                >
                  <InputPhone placeholder={formatMessage({ id: "auth.register.fields.businessCountry.selectCountry" })} />
                </Form.Item>
              </Grid.Item>

              <Grid.Item xs={12}>
                <Form.Item
                  label={formatMessage({ id: "auth.register.fields.businessCountry.label" })}
                  name="country"
                  rules={[{ required: true, message: formatMessage({ id: "auth.register.fields.businessCountry.required" }) }]}
                >
                  <SelectCountry placeholder={formatMessage({ id: "auth.register.fields.businessCountry.selectCountry" })} />
                </Form.Item>
              </Grid.Item>

              <Grid.Item xs={24} md={12}>
                <Form.Item label={formatMessage({ id: "auth.register.fields.signupCode.label" })} name="code" hidden={partner && partnerCodes[partner]}>
                  <Input name="code" />
                </Form.Item>
              </Grid.Item>
            </Grid>

            <Grid.Item xs={24}>
              <PasswordField label={formatMessage({ id: "auth.register.fields.password.label" })} applyTranslations />
            </Grid.Item>

            <Form.Item
              name="terms"
              valuePropName="checked"
              rules={[
                {
                  async validator(rule, value) {
                    if (!value) {
                      throw formatMessage({ id: "auth.password.reset.acceptTerms" });
                    }
                  },
                },
              ]}
            >
              <Checkbox name="terms">
                {formatMessage(
                  {
                    id: "auth.password.reset.agreement.iAgree",
                  },
                  {
                    termsOfService: (
                      <TermsOfServiceLink locale={locale}> {formatMessage({ id: "auth.password.reset.agreement.termsOfService" })}</TermsOfServiceLink>
                    ),
                    privacyPolicy: (
                      <TermsOfServiceLink locale={locale}> {formatMessage({ id: "auth.password.reset.agreement.privacyPolicy" })}</TermsOfServiceLink>
                    ),
                  },
                )}
              </Checkbox>
            </Form.Item>

            {consentRequired ? (
              <Form.Item name="consent" valuePropName="checked" initialValue={false}>
                <Checkbox name="consent">
                  {formatMessage(
                    {
                      id: "auth.register.newslettersConsentRequired",
                    },
                    {
                      privacyPolicy: (
                        <TermsOfServiceLink locale={locale}>{formatMessage({ id: "auth.password.reset.agreement.privacyPolicy" })}</TermsOfServiceLink>
                      ),
                    },
                  )}
                </Checkbox>
              </Form.Item>
            ) : (
              <Text type="secondary">
                {formatMessage(
                  {
                    id: "auth.register.newslettersNoConsent",
                  },
                  {
                    privacyPolicy: (
                      <TermsOfServiceLink locale={locale}>{formatMessage({ id: "auth.password.reset.agreement.privacyPolicy" })}</TermsOfServiceLink>
                    ),
                  },
                )}
              </Text>
            )}

            <Divider transparent margin="small" />

            <Button
              type="primary"
              style={{ display: "block", width: "50%", margin: "auto" }}
              htmlType="submit"
              size="large"
              loading={userStatus === BaseStatus.LOADING}
            >
              {formatMessage({ id: "auth.login.signup" })}
            </Button>
            <Divider transparent margin="small" />
            <Text type="secondary" align="center">
              {formatMessage({ id: "auth.register.alreadyHaveAccount" })}{" "}
              <Link to={getPublicPath(PublicModulesEnum.Login, locale)}>{formatMessage({ id: "auth.login.login" })}</Link>
            </Text>
          </Form>
        </>
      )}
    </FullscreenAuth>
  );
}
