import {
  BankAccountType,
  BankProvider,
  BaseError,
  CountryCode,
  CurrencyCode,
  NoBankingCountries,
  NoUsdAchPayoutMethodAllowedCountries,
  PayoutMethodType,
  Rules,
  formatCountry,
  getBankRuleProps,
  getBankRules,
  getCurrencyLabel,
  isValidIbanSwiftAccountCountry,
} from "@trolley/common-frontend";
import deepEqual from "fast-deep-equal";
import debounce from "lodash.debounce";
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { Alert, Button, Checkbox, Divider, Flag, Form, Grid, Icon, Input, Radio, Select, SelectCountry, SelectRegion, Text } from "components";
import { FormInstance } from "components/Form";
import { BankDisplay } from "features/payoutMethod";
import { BankInfo, BankParameters, loadBankInfo } from "store/actions/bankInfo";
import { CountryCurrency } from "store/actions/currencies";
import { RecipientAccount, RecipientAccountUpdate } from "store/actions/recipientAccount";
import { loadAsyncRecipientAccountConfig } from "store/actions/recipientAccountConfig";
import { StoreRecipient } from "store/actions/recipients";
import { useBankCodes } from "store/hooks/bankCodes";
import { MerchantSettings, useMerchantSettings } from "store/hooks/merchantSettings";
import { usePayoutMethods } from "store/hooks/payoutMethods";
import { BaseStatus } from "store/reducers/standardReducer";
import { calculateJaccardSimilarity, handleFormErrors, omitMaskedValues, pick } from "utils/helpers";

export const FIELDS_REQUIRING_BANK_LOOKUP = ["country", "currency", "iban", "swiftBic", "branchId", "bankId", "accountNum"];

function getMainCurrency(countryCurrencies: CountryCurrency[] | undefined) {
  return countryCurrencies?.find((currency) => currency.isPrimary)?.currency || countryCurrencies?.[0]?.currency;
}

function getBankFieldRulesIndex(rules: { label: string; bankFields: Rules }[], account: RecipientAccount) {
  const cleanedAccount = Object.entries(account) // Removes undefined and empty values
    .filter(([_, v]) => v)
    .map(([k, _]) => k);

  let closestIndex = 0;
  let closestSimilarity = 0;

  rules.forEach((raConfig, index) => {
    const similarity = calculateJaccardSimilarity(raConfig.bankFields, cleanedAccount);
    if (similarity > closestSimilarity) {
      closestIndex = index;
      closestSimilarity = similarity;
    }
  });

  return closestIndex;
}

function getDefaultBankRule(merchantSettings: MerchantSettings | undefined, payoutCountry: CountryCode, currency: CurrencyCode) {
  return merchantSettings?.bankProvider !== BankProvider.PRL
    ? getBankRules(payoutCountry, currency).filter((rule) => !rule.bankFields.includes("bankCodeMappingId"))
    : getBankRules(payoutCountry, currency);
}

type Props = {
  currencies: Partial<Record<CountryCode, CountryCurrency[]>>;
  recipient: StoreRecipient;
  recipientAccount: RecipientAccount | undefined;
  onSubmit(changes?: RecipientAccountUpdate): Promise<void>;
};

interface FormFields {
  currency: CurrencyCode;
  country: CountryCode;
  iban?: string;
  bankAccountType?: BankAccountType;
  accountNum?: string;
  accountHolderName?: string;
  swiftBic?: string;
  branchId?: string;
  bankId?: string;
  bankName?: string;
  bankAddress?: string;
  bankCity?: string;
  bankRegionCode?: string;
  bankCodeMappingId?: string[];

  primary?: boolean;
  bankInfo?: BankInfo | BaseStatus;
}

export default forwardRef<FormInstance, Props>((props, ref) => {
  const [errors, setErrors] = useState<BaseError[]>();
  const { currencies, recipientAccount, recipient, onSubmit } = props;
  const { data: payoutMethods } = usePayoutMethods();
  const { features, data: merchantSettings } = useMerchantSettings();
  const [form] = Form.useForm<FormFields>();
  const bankInfo: BankInfo | BaseStatus | undefined = Form.useWatch("bankInfo", form);
  const payoutCountry: CountryCode = Form.useWatch("country", form) || recipientAccount?.country; // we can fallback because country is non-editable when account exists. this prevents multiple useEffect trigger
  const currency: CurrencyCode = Form.useWatch("currency", form) || recipientAccount?.currency;

  const [bankRulesIndex, setBankRulesIndex] = useState(0);
  const [allRules, setAllRules] = useState(getDefaultBankRule(merchantSettings, payoutCountry, currency));

  const countryRule = useMemo(() => allRules[bankRulesIndex]?.bankFields || [], [allRules, bankRulesIndex]);

  const { data: bankCodes } = useBankCodes(payoutCountry, currency);
  const payoutCountryCurrencies = (payoutCountry && currencies[payoutCountry]) || [];
  const bankTransferSettings = payoutMethods?.find((pm) => pm.integration === PayoutMethodType.BANKTRANSFER);

  const shouldExcludeUSFromBankCountry =
    NoUsdAchPayoutMethodAllowedCountries.includes(recipient.address.country as CountryCode) &&
    merchantSettings?.bankProvider &&
    [BankProvider.PRL, BankProvider.CCEU].includes(merchantSettings?.bankProvider);
  /**
   * when a country or currency changes, we fetch the new bank rules (BankFieldType[]) and reset the bankRulesIndex
   * */
  useEffect(() => {
    if (payoutCountry && currency && recipient) {
      loadAsyncRecipientAccountConfig({
        recipientId: recipient.id,
        accountType: PayoutMethodType.BANKTRANSFER,
        countryCode: payoutCountry,
        currencyCode: currency,
      })
        .then((recipientAccountConfig) => {
          updateAllRules(
            recipientAccountConfig?.requiredFields.length
              ? recipientAccountConfig.requiredFields
              : getDefaultBankRule(merchantSettings, payoutCountry, currency),
          );
        })
        .catch(() => {
          updateAllRules(getDefaultBankRule(merchantSettings, payoutCountry, currency));
        });
    }
  }, [payoutCountry, currency]);

  function updateAllRules(allNewRules: ReturnType<typeof getBankRules>) {
    if (!deepEqual(allNewRules, allRules)) {
      setAllRules(allNewRules);
    }

    if (recipientAccount) {
      const ruleIndex = getBankFieldRulesIndex(allNewRules, recipientAccount);
      setBankRulesIndex(ruleIndex > 0 ? ruleIndex : 0);
    } else {
      setBankRulesIndex(0);
    }
  }

  useEffect(() => {
    if (typeof ref === "function") {
      ref(form);
    }
  }, [form]);

  async function onFinish({ primary, bankInfo, ...formValues }: FormFields) {
    const values = omitMaskedValues(formValues);
    const keysChanged = Object.keys(values).filter((key) => {
      if (!recipientAccount) {
        return true; // if new account, save all keys
      }

      if (key in recipientAccount && !!values[key]) {
        return recipientAccount[key] !== values[key];
      }

      return false;
    });

    try {
      await onSubmit(
        keysChanged.length
          ? omitMaskedValues({
              ...pick(values, keysChanged),
              primary,
              type: PayoutMethodType.BANKTRANSFER,
            })
          : undefined,
      );
    } catch (errors) {
      handleFormErrors(errors, form);
    }
  }

  function onBankInfoChanged(data: BankParameters) {
    form.setFieldsValue({ bankInfo: BaseStatus.LOADING });

    loadBankInfo(data)
      .then((bankInfo) => {
        form.setFieldsValue({ bankInfo });
      })
      .catch((err: BaseError[]) => {
        form.setFieldsValue({ bankInfo: BaseStatus.ERROR });
        setErrors(err);
      });
  }

  const onDebouncedChange = useCallback(
    debounce(() => {
      const fieldsValue = form.getFieldsValue();
      const validValues = countryRule.every((rule) => {
        if (!FIELDS_REQUIRING_BANK_LOOKUP.includes(rule)) {
          return true;
        }

        if (!fieldsValue[rule]) {
          return false;
        }

        return !fieldsValue[rule]?.includes("*") || rule === "accountNum"; // we can allow any values for AccountNum;
      });

      const hasErrors = Object.values(form.getFieldsError(FIELDS_REQUIRING_BANK_LOOKUP)).some((v) => v.errors.length); // Errors on fields that matters to the bank lookup
      if (!hasErrors && validValues) {
        onBankInfoChanged(fieldsValue);
      }
    }, 650),
    [countryRule],
  );

  function onValuesChange(changes: Partial<FormFields>) {
    if (!changes.bankCodeMappingId && FIELDS_REQUIRING_BANK_LOOKUP.some((field) => field in changes)) {
      onDebouncedChange();
    }
  }

  if (!recipientAccount) {
    if (!recipient.address.country) {
      return <Alert type="warning">Recipient address is missing. Bank Transfer cannot be setup.</Alert>;
    } else if (NoBankingCountries.includes(recipient.address.country)) {
      return (
        <Alert type="warning">
          Bank Transfer is not available in{" "}
          <Text weight="bold" inline>
            {formatCountry(recipient.address.country)}
          </Text>
          .
        </Alert>
      );
    }
  }

  const showCountrySelector = features.allowUSBankTransferAccount && !recipientAccount && recipient.address.country !== CountryCode.US;

  return (
    <Form
      form={form}
      initialValues={
        recipientAccount || {
          country: recipient.address.country,
          currency: recipient.address.country ? getMainCurrency(currencies[recipient.address.country]) : undefined,
        }
      }
      onValuesChange={onValuesChange}
      onFinish={onFinish}
    >
      {!bankTransferSettings?.enabled && (
        <Alert type="warning">
          Bank Transfer is disabled. Go to <Link to="/settings/payout-methods/bank-transfer">Bank Transfer Settings</Link> to enable it.
        </Alert>
      )}
      {bankTransferSettings && bankTransferSettings.enabledCountries.length > 0 && !bankTransferSettings.enabledCountries.includes(payoutCountry) && (
        <Alert type="warning">
          Bank Transfer to {formatCountry(payoutCountry)} is disabled. Only recipient bank accounts located in enabled countries will be payable. Go to{" "}
          <Link to="/settings/payout-methods/bank-transfer">Bank Transfer Settings</Link> to enable/disable countries.
        </Alert>
      )}

      <Grid padding={["medium", "none"]}>
        <Grid.Item xs={24} md={12} key="bank_country">
          <Form.Item
            label="Bank Account Country"
            name="country"
            rules={[
              {
                required: !!showCountrySelector,
                message: "Country is required",
              },
            ]}
          >
            <SelectCountry
              disabled={!showCountrySelector}
              onChange={(country: CountryCode) => {
                if (!recipientAccount) {
                  const payoutCountryCurrencies = (country && currencies[country]) || [];
                  form.setFieldsValue({
                    currency: getMainCurrency(payoutCountryCurrencies),
                  });
                }
              }}
              includes={[CountryCode.US, payoutCountry, recipient.address.country as CountryCode].filter((c) => !!c)}
              excludes={shouldExcludeUSFromBankCountry ? [CountryCode.US] : []}
            />
          </Form.Item>
        </Grid.Item>
        <Grid.Item xs={24} md={12}>
          <Form.Item label="Bank Account Currency" name="currency" dependencies={["country"]}>
            <Select<CurrencyCode> disabled={!!recipientAccount}>
              {payoutCountryCurrencies.map((c) => (
                <Select.Option key={c.currency} value={c.currency}>
                  <Flag code={c.currency} showLabel={false} /> {c.currency} - {getCurrencyLabel(c.currency, true)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Grid.Item>

        {!recipientAccount && allRules.length > 1 && (
          <Grid.Item xs={24}>
            <Radio.Group
              gap={["large", "small"]}
              style={{ marginBottom: "16px" }}
              value={bankRulesIndex}
              onChange={(e) => {
                setBankRulesIndex(Number(e.target.value));
              }}
              options={allRules.map((rules, index) => ({ value: index, label: rules.label }))}
            />
          </Grid.Item>
        )}

        <Grid.Item xs={24} md={12}>
          {countryRule.map((field) => (
            <Form.Control dependencies={[field]} key={field}>
              {({ getFieldValue }) => {
                const bankRule = getBankRuleProps(field, getFieldValue(field), {
                  country: payoutCountry,
                  currency,
                  bankId: getFieldValue("bankId"),
                });

                const bankRegionRule =
                  field === "bankCity" &&
                  getBankRuleProps("bankRegionCode", getFieldValue("bankRegionCode"), {
                    country: payoutCountry,
                    currency,
                  });

                if (!bankRule) {
                  return null;
                }

                return (
                  <>
                    <Form.Item
                      label={bankRule.label === "Branch Number" && payoutCountry === CountryCode.BD ? `Branch Number (BB Code)` : bankRule.label}
                      tooltip={bankRule.tooltip}
                      extra={bankRule.hint}
                      name={field}
                      normalize={bankRule.normalize}
                      dependencies={FIELDS_REQUIRING_BANK_LOOKUP}
                      rules={[
                        ...(bankRule?.rules || []),
                        (form) => ({
                          async validator(rules: any, value: string) {
                            if (["iban", "swiftBic"].includes(field)) {
                              const { currency, country } = form.getFieldsValue();
                              const substringStart = field === "iban" ? 0 : 4;
                              if (value && !value.includes("*")) {
                                const accountCountryStr = String(value || "")
                                  .substring(substringStart, substringStart + 2)
                                  .toLocaleUpperCase();
                                const accountCountry = accountCountryStr in CountryCode ? (accountCountryStr as CountryCode) : undefined;

                                if (
                                  accountCountry &&
                                  !isValidIbanSwiftAccountCountry({
                                    payoutCountry: country,
                                    payoutCurrency: currency,
                                    accountCountry,
                                    accountCountryAcceptedCurrencies: (accountCountry && currencies[accountCountry]?.map((v) => v.currency)) || [],
                                  })
                                ) {
                                  throw `This ${field === "iban" ? "IBAN" : "Swift/BIC"} cannot be used in ${formatCountry(payoutCountry)}`;
                                }
                              }
                            } else if (field === "branchId" && payoutCountry === CountryCode.BD && value) {
                              // Validate branchId: Must be exactly 9 digits (numeric only)
                              const branchIdPattern = /^\d{9}$/;
                              if (!branchIdPattern.test(value)) {
                                throw "Branch number must be exactly 9 numeric digits.";
                              }
                            }
                          },
                        }),
                      ]}
                    >
                      {bankRule.options ? (
                        <Select
                          options={field === "bankCodeMappingId" ? bankCodes.map((code) => ({ label: code.bankName, value: code.id })) : bankRule.options}
                          defaultActiveFirstOption
                          placeholder={bankRule.placeholder}
                        />
                      ) : (
                        <Input
                          key={field}
                          name={field}
                          placeholder={bankRule.placeholder}
                          suffix={
                            bankRule.warning ? (
                              <Icon.Status type="warning" tooltip={bankRule.warning} />
                            ) : (
                              <span /> // FIX: https://ant.design/components/input/#Why-Input-lose-focus-when-change-prefix/suffix
                            )
                          }
                        />
                      )}
                    </Form.Item>

                    {bankRegionRule && (
                      <Form.Item label={bankRegionRule.label} name="bankRegionCode" rules={bankRegionRule.rules}>
                        <SelectRegion country={payoutCountry} />
                      </Form.Item>
                    )}
                  </>
                );
              }}
            </Form.Control>
          ))}

          <Form.Control dependencies={["accountHolderName"]}>
            {({ getFieldValue }) => {
              const accountName = getFieldValue("accountHolderName");
              const accountNameRule = getBankRuleProps("accountHolderName", accountName, {
                country: payoutCountry,
                currency,
              });

              return (
                accountNameRule && (
                  <Form.Item label={accountNameRule.label} tooltip={accountNameRule.tooltip} name="accountHolderName" rules={accountNameRule.rules}>
                    <Input name="accountHolderName" placeholder="John Appleseed" />
                  </Form.Item>
                )
              );
            }}
          </Form.Control>
        </Grid.Item>
        {!countryRule.includes("bankCodeMappingId") ? (
          <Grid.Item xs={24} md={12}>
            <Form.Item
              name="bankInfo"
              rules={[
                ({ isFieldsTouched }) => ({
                  async validator(rule, bankInfo) {
                    const bankLookupChanged = isFieldsTouched(FIELDS_REQUIRING_BANK_LOOKUP);
                    if (bankLookupChanged && !countryRule.includes("bankCodeMappingId")) {
                      if (typeof bankInfo !== "object") {
                        throw "Valid banking details is required";
                      }
                    }
                  },
                }),
              ]}
            >
              <BankDisplay
                bankInfo={typeof bankInfo === "object" ? bankInfo : undefined}
                bankInfoStatus={typeof bankInfo !== "object" ? bankInfo : undefined}
                account={recipientAccount}
                showAddress={!countryRule.includes("swiftBic")}
                errors={errors}
              />
            </Form.Item>
          </Grid.Item>
        ) : null}
      </Grid>

      {!recipientAccount && (
        <>
          <Divider margin="small" transparent />
          <Form.Item name="primary" valuePropName="checked" noStyle>
            <Checkbox>Set Account as Active</Checkbox>
          </Form.Item>
        </>
      )}
      <Button hidden htmlType="submit" />
    </Form>
  );
});
