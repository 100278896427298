import { CountryCode, countryHasPostalCode, OrganizationType, TaxFormType, TaxW8LimitationTypes } from "@trolley/common-frontend";
import { Alert, Checkbox, DatePicker, Form, Grid, Icon, Input, Select, SelectCountry, SelectRegion } from "components";
import { styledRemoveIcon } from "components/Form/Input";
import React from "react";
import { W8BENForm } from "store/actions/taxForms";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { translateRegionByCountry } from "utils/helpers";
import { isRegionInCountryAsyncValidator } from "../Info";
import { irsRule, latinRule, latinRuleIgnoreStar } from "./variables";

export const w8taxOrgTypes: Record<OrganizationType, string> = {
  [OrganizationType.INDIVIDUAL]: "Individual",
  [OrganizationType.CORPORATION]: "Corporation",
  [OrganizationType.PARTNERSHIP]: "Partnership",
  [OrganizationType.CENTRAL_BANK]: "Central Bank of Issue",
  [OrganizationType.COMPLEX_TRUST]: "Complex Trust",
  [OrganizationType.DISREGARDED_ENTITY]: "Disregarded Entity",
  [OrganizationType.ESTATE]: "Estate",
  [OrganizationType.GOVERNMENT]: "Government",
  [OrganizationType.GRANTOR_TRUST]: "Grantor Trust",
  [OrganizationType.INTERNATIONAL_ORG]: "International Organization",
  [OrganizationType.PRIVATE_FOUNDATION]: "Private Foundation",
  [OrganizationType.SIMPLE_TRUST]: "Simple Trust",
  [OrganizationType.TAX_EXEMPT_ORGANIZATION]: "Tax-Exempt Organization",
};

export const w8limitationTypes: Record<TaxW8LimitationTypes, string> = {
  [TaxW8LimitationTypes.GOVERNMENT]: "Government",
  [TaxW8LimitationTypes.TAX_EXEMPT_PENSION]: "Tax exempt pension trust or pension fund",
  [TaxW8LimitationTypes.TAX_EXEMPT_ORGANIZATION]: "Other tax exempt organization",
  [TaxW8LimitationTypes.PUBLICLY_TRADED_CORP]: "Publicly traded corporation",
  [TaxW8LimitationTypes.SUBSIDIARY_PUBLICLY_TRADED_CORP]: "Subsidiary of a publicly traded corporation",
  [TaxW8LimitationTypes.MEETS_EROSION_TEST]: "Company that meets the ownership and base erosion test",
  [TaxW8LimitationTypes.MEETS_BENEFITS_TEST]: "Company that meets the derivative benefits test",
  [TaxW8LimitationTypes.MEETS_TRADE_TEST]: "Company with an item of income that meets active trade or business test",
  [TaxW8LimitationTypes.FAVORABLE_DISCRETIONARY]: "Favorable discretionary determination by the U.S. competent authority received",
  [TaxW8LimitationTypes.OTHER]: "Other (specify Article and paragraph)",
};

function getName(key: keyof W8BENForm) {
  return ["data", "w8Data", key];
}

export default function W8UploadForm() {
  const { data: merchantSettings } = useMerchantSettings();
  const form = Form.useFormInstance();
  const uploadKind = Form.useWatch(["data", "uploadKind"], form);
  const mailingCountry = Form.useWatch(getName("mailingCountry"), form);

  return (
    <>
      {uploadKind === TaxFormType.W8BEN && (
        <>
          <Grid.Item xs={24} md={12}>
            <Form.Item label="First Name" name={getName("firstName")} rules={[{ required: true, message: "Enter the First Name" }, irsRule]}>
              <Input name="firstName" />
            </Form.Item>
          </Grid.Item>
          <Grid.Item xs={24} md={12}>
            <Form.Item label="Last Name" name={getName("lastName")} rules={[{ required: true, message: "Enter the Last Name" }, irsRule]}>
              <Input name="lastName" />
            </Form.Item>
          </Grid.Item>
          <Grid.Item xs={24}>
            <Form.Item label="Date of Birth" name={getName("dob")}>
              <DatePicker type="past" />
            </Form.Item>
          </Grid.Item>
        </>
      )}

      {uploadKind === TaxFormType.W8BENE && (
        <>
          <Grid.Item xs={24} md={12}>
            <Form.Item
              label="Name of Organization"
              name={getName("organizationName")}
              rules={[{ required: true, message: "Enter the Name of the Organization" }, irsRule]}
            >
              <Input name="organizationName" />
            </Form.Item>
          </Grid.Item>

          <Grid.Item xs={24} md={12}>
            <Form.Item
              label="Type of Organization Entity"
              name={getName("organizationType")}
              rules={[{ required: true, message: "Select the type of organization entity" }]}
            >
              <Select>
                {Object.entries(w8taxOrgTypes).map(([orgType, label]) => (
                  <Select.Option key={orgType} value={orgType}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Grid.Item>
          <Grid.Item xs={24}>
            <Form.Control dependencies={[getName("beneficialOwnerLimitationType")]}>
              {({ getFieldValue }) => {
                const beneficialOwnerLimitationType = getFieldValue(getName("beneficialOwnerLimitationType"));
                const showSelect =
                  beneficialOwnerLimitationType === undefined || Object.entries(w8limitationTypes).some(([k]) => k === beneficialOwnerLimitationType);

                return (
                  <Form.Item
                    label="Beneficial Owner Limitation Type"
                    name={getName("beneficialOwnerLimitationType")}
                    dependencies={[getName("beneficialOwnerLimitationType")]}
                    rules={[{ required: true, message: "Beneficial owner limitation type is requried" }]}
                  >
                    {showSelect ? (
                      <Select
                        placeholder="Select a limitation type"
                        onChange={(val) => {
                          if (val === TaxW8LimitationTypes.OTHER) {
                            form.setFieldsValue({ data: { w8Data: { beneficialOwnerLimitationType: "" } } });
                          }
                        }}
                      >
                        {Object.entries(w8limitationTypes).map(([limitationType, label]) => (
                          <Select.Option key={limitationType} value={limitationType}>
                            {label}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <Input
                        className={styledRemoveIcon}
                        placeholder="Other"
                        suffix={
                          <Icon
                            color="grey"
                            theme="solid"
                            type="circle-xmark"
                            tooltip="Clear"
                            onClick={() => {
                              // undefined will show Select component again
                              form.setFieldsValue({ data: { w8Data: { beneficialOwnerLimitationType: undefined } } });
                            }}
                          />
                        }
                        autoFocus
                      />
                    )}
                  </Form.Item>
                );
              }}
            </Form.Control>
          </Grid.Item>
        </>
      )}

      <Grid.Item xs={24} md={12}>
        <Form.Item
          label={uploadKind === TaxFormType.W8BEN ? "Country of Citizenship" : "Country of Incorporation or Organization"}
          name={getName("organizationCountry")}
          dependencies={[["data", "uploadKind"]]}
          rules={[{ required: true, message: "Select a country" }]}
        >
          <SelectCountry type="all" />
        </Form.Item>
      </Grid.Item>

      <Grid.Item xs={24} md={12}>
        <Form.Item label="Country of Residence" name={getName("residenceCountry")} rules={[{ required: true, message: "Enter the country of residence" }]}>
          <SelectCountry type="all" excludes={[CountryCode.US]} />
        </Form.Item>
      </Grid.Item>

      <Grid.Item xs={24}>
        <Form.Item label="Mailing Country" name={getName("mailingCountry")} rules={[{ required: true, message: "Enter the mailing country" }]}>
          <SelectCountry type="all" />
        </Form.Item>
      </Grid.Item>
      {mailingCountry && (
        <>
          <Grid.Item xs={24} md={12}>
            <Form.Item label="Mailing Address" name={getName("mailingAddress")} rules={[{ required: true, message: "Enter the street address" }, latinRule]}>
              <Input name="mailingAddress" />
            </Form.Item>
          </Grid.Item>
          <Grid.Item xs={24} md={12}>
            <Form.Item label="Mailing City" name={getName("mailingCity")} rules={[{ required: true, message: "Enter the City" }, latinRule]}>
              <Input name="mailingCity" />
            </Form.Item>
          </Grid.Item>
          <Grid.Item xs={24} md={12}>
            <Form.Item
              label={`Mailing ${translateRegionByCountry(mailingCountry)}`}
              name={getName("mailingRegion")}
              dependencies={[getName("mailingCountry")]}
              rules={[
                { required: true, message: `Enter the ${translateRegionByCountry(mailingCountry)}` },
                isRegionInCountryAsyncValidator(getName("mailingCountry")),
              ]}
            >
              <SelectRegion country={mailingCountry} />
            </Form.Item>
          </Grid.Item>
          <Grid.Item xs={24} md={12}>
            <Form.Item
              label="Mailing Postal Code"
              name={getName("mailingPostalCode")}
              dependencies={[getName("mailingCountry")]}
              rules={[{ required: countryHasPostalCode(mailingCountry), message: "Enter a postal Code" }, latinRule]}
            >
              <Input name="mailingPostalCode" />
            </Form.Item>
          </Grid.Item>
        </>
      )}

      <Form.Control shouldUpdate>
        {({ getFieldValue }) => {
          const noTaxId = getFieldValue(getName("noTaxId"));

          return (
            <>
              <Grid.Item xs={24} md={12}>
                <Form.Item
                  label="U.S. Taxpayer Identification Number (TIN)"
                  name={getName("taxPayerUsId")}
                  dependencies={[getName("noTaxId"), getName("taxPayerForeignId")]}
                  rules={[
                    ({ getFieldValue }) => ({
                      required: !getFieldValue(getName("noTaxId")) && !getFieldValue(getName("taxPayerForeignId")),
                      message: "An U.S. or Foreign Tax Identification Number is required",
                    }),
                    latinRuleIgnoreStar,
                  ]}
                >
                  <Input name="taxPayerUsId" placeholder="XX-XXXXXXXX or XXX-XX-XXXX" disabled={noTaxId} />
                </Form.Item>
              </Grid.Item>
              <Grid.Item xs={24} md={12}>
                <Form.Item
                  label="Foreign Tax Identification Number"
                  name={getName("taxPayerForeignId")}
                  dependencies={[getName("noTaxId"), getName("taxPayerUsId")]}
                  rules={[
                    ({ getFieldValue }) => ({
                      required: !getFieldValue(getName("noTaxId")) && !getFieldValue(getName("taxPayerUsId")),
                      message: "An U.S. or Foreign Tax Identification Number is required",
                    }),
                    latinRuleIgnoreStar,
                  ]}
                >
                  <Input name="taxPayerForeignId" disabled={noTaxId} />
                </Form.Item>
              </Grid.Item>
              <Grid.Item xs={24}>
                <Form.Item
                  name={getName("noTaxId")}
                  valuePropName="checked"
                  dependencies={[getName("taxPayerForeignId"), getName("taxPayerUsId")]}
                  initialValue={false}
                >
                  <Checkbox
                    onChange={(checked) => {
                      if (checked) {
                        form.setFields([
                          { name: getName("taxPayerUsId"), value: undefined },
                          { name: getName("taxPayerForeignId"), value: undefined },
                        ]);
                      }
                    }}
                  >
                    I am unable to provide a Tax Identification Number.
                  </Checkbox>
                </Form.Item>
              </Grid.Item>
            </>
          );
        }}
      </Form.Control>

      <Grid.Item xs={24}>
        <Form.Item
          label="Non-US Activities Certification"
          name={getName("noUsCertification")}
          initialValue
          valuePropName="checked"
          required
          tooltip={
            <>
              The underlying services provided will be performed solely outside of the United States, and that any capital (including facilities, computer
              servers, and other tools) utilized to provide such services will be physically located outside of the United States.
            </>
          }
        >
          <Checkbox>Recipient provides services exclusively from outside of the United States.</Checkbox>
        </Form.Item>

        <Form.Control dependencies={[getName("noTaxId"), getName("noUsCertification")]}>
          {({ getFieldValue }) => {
            const noTaxId = getFieldValue(getName("noTaxId"));
            const noUsCertification = getFieldValue(getName("noUsCertification"));
            // Tax Setting: "On W-8 forms, if the recipient certifies to provide services exclusively outside of the United States, but does not provide a Foreign TIN, how would you like to handle it?"
            const w8WithholdingWarningEnabled = !!noUsCertification && merchantSettings?.tax?.displayW8WithholdingWarning && !!noTaxId;

            return (
              (!noUsCertification || w8WithholdingWarningEnabled) && (
                <Alert type="warning">Payments to this recipient may be subject to a 30% Withholding rate.</Alert>
              )
            );
          }}
        </Form.Control>
      </Grid.Item>
    </>
  );
}
