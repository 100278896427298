import { CurrencyCode } from "@trolley/common-frontend";

export type IssuedCreditNote = {
  cn_id: string;
  cn_reason_code?:
    | "write_off"
    | "subscription_change"
    | "subscription_cancellation"
    | "subscription_pause"
    | "chargeback"
    | "product_unsatisfactory"
    | "service_unsatisfactory"
    | "order_change"
    | "order_cancellation"
    | "waiver"
    | "other"
    | "fraudulent";
  cn_create_reason_code?: string;
  cn_date?: number;
  cn_total?: number;
  cn_status: "adjusted" | "refunded" | "refund_due" | "voided";
};

export type IssuedCreditNoteWithCurrency = IssuedCreditNote & {
  currency_code: CurrencyCode;
};

export function isIssuedCreditNote(obj: any): obj is IssuedCreditNote {
  return (
    typeof obj === "object" &&
    obj !== null &&
    typeof obj.cn_id === "string" &&
    (!obj.cn_reason_code || isValidReasonCode(obj.cn_reason_code)) &&
    (!obj.cn_create_reason_code || typeof obj.cn_create_reason_code === "string") &&
    (!obj.cn_date || typeof obj.cn_date === "number") &&
    (!obj.cn_total || typeof obj.cn_total === "number") &&
    isValidStatus(obj.cn_status)
  );
}

// Helper functions to validate reason codes and statuses
const validReasonCodes: Set<string> = new Set([
  "write_off",
  "subscription_change",
  "subscription_cancellation",
  "subscription_pause",
  "chargeback",
  "product_unsatisfactory",
  "service_unsatisfactory",
  "order_change",
  "order_cancellation",
  "waiver",
  "other",
  "fraudulent",
]);

const validStatuses: Set<string> = new Set(["adjusted", "refunded", "refund_due", "voided"]);

function isValidReasonCode(code: any): boolean {
  return typeof code === "string" && validReasonCodes.has(code);
}

function isValidStatus(status: any): boolean {
  return typeof status === "string" && validStatuses.has(status);
}
