import * as patterns from "@trolley/common-frontend/lib/validators";
import { Form, Icon, Input } from "components";
import React from "react";
import { useIntl } from "utils/context";
import { checkPasswordStrength, PasswordStrength } from "utils/helpers";

export interface Props {
  label: string;
  size?: "large" | "small";
  applyTranslations?: boolean;
}

export default function PasswordField(props: Props) {
  const { label, size } = props;
  const { formatMessage } = useIntl();
  const password = Form.useWatch(["password"]);
  const validPassword = {
    length: !!password && patterns.hasMinPasswordLength.test(password),
    lowercase: !!password && patterns.hasLowercase.test(password),
    uppercase: !!password && patterns.hasUppercase.test(password),
    number: !!password && patterns.hasDigit.test(password),
    special: !!password && patterns.hasSpecialChar.test(password),
  };
  function getLabel(translatedLabel: string, defaultLabel: string) {
    return !!props.applyTranslations ? translatedLabel : defaultLabel;
  }

  return (
    <Form.Item
      validateTrigger="onChange"
      label={label}
      name="password"
      rules={[
        { required: true, message: getLabel(formatMessage({ id: "auth.password.field.rules.required" }), "Password is required. ") },
        {
          async validator(rule, passwordValue) {
            const validPassword = {
              length: !!passwordValue && patterns.hasMinPasswordLength.test(passwordValue),
              lowercase: !!passwordValue && patterns.hasLowercase.test(passwordValue),
              uppercase: !!passwordValue && patterns.hasUppercase.test(passwordValue),
              number: !!passwordValue && patterns.hasDigit.test(passwordValue),
              special: !!passwordValue && patterns.hasSpecialChar.test(passwordValue),
            };
            const errorMessages = [
              getLabel(formatMessage({ id: "auth.password.field.rules.eightCharacters" }), "at least 8 characters are required. "),
              getLabel(formatMessage({ id: "auth.password.field.rules.aLowerCase" }), "a lower case letter is required. "),
              getLabel(formatMessage({ id: "auth.password.field.rules.aCapitalLetter" }), "a capital letter is required. "),
              getLabel(formatMessage({ id: "auth.password.field.rules.aNumber" }), "a number is required. "),
              getLabel(formatMessage({ id: "auth.password.field.rules.aSpecialCharacter" }), "a special character is required. "),
            ];

            const validationResults = [validPassword.length, validPassword.lowercase, validPassword.uppercase, validPassword.number, validPassword.special];
            const actualErrorMessages = errorMessages.filter((message, index: number) => !validationResults[index]);
            if (actualErrorMessages.length > 0) {
              const [lastMessage, ...restMessages] = [...actualErrorMessages].reverse();
              if (restMessages.length === 0) {
                throw formatMessage({ id: "auth.password.field.rules.passwordMustHave" }, { singleErrorMessage: lastMessage });
              }
              restMessages.reverse();
              throw formatMessage(
                { id: "auth.password.field.rules.passwordMustHaveAnd" },
                {
                  errorMessages: `${restMessages.reverse().join(", ")}${restMessages.length > 1 ? "," : ""}`,
                  lastErrorMessage: lastMessage,
                },
              );
            }
          },
        },
        {
          async validator(rule, passwordValue) {
            const passwordStrength = checkPasswordStrength(password);
            if (passwordStrength === PasswordStrength.WEAK) {
              throw formatMessage({ id: "auth.password.field.tooltip.weakPassword" });
            }
          },
        },
      ]}
    >
      <Input
        autoComplete="new-password"
        size={size}
        type="password"
        toggleVisibility
        data-testid="password"
        suffix={
          password && Object.values(validPassword).some((v) => !v) ? (
            <Icon.Status type="error" />
          ) : (
            <span /> // this is need otherwise the input will lose focus
          )
        }
      />
    </Form.Item>
  );
}
