import { UploadType } from "@trolley/common-frontend";
import TaxFormCSVTemplate from "assets/w9_w8_upload_template.2025.03.05.1.zip";
import { Icon, Text, UploadWindow } from "components";
import { MAX_FILE_SIZE } from "components/UploadWindow";
import React from "react";
import { useHistory } from "react-router-dom";
import { fileUpload } from "store/actions/files";
import { notifyError } from "store/actions/notifications";
import { useShallowSelector } from "store/hooks";
import { BaseStatus } from "store/reducers/standardReducer";

interface Props {
  visible: boolean;
  onClose(): void;
}

export default function UploadTaxForms({ visible, onClose }: Props) {
  const history = useHistory();
  const fileUploadStatus = useShallowSelector((state) => state.files.fetchStatus.data);

  async function onUpload(file: FormData) {
    try {
      const id = await fileUpload(UploadType.TAX_FORMS, file);
      onClose();
      history.push(`/tax-center/tax-forms/upload/${id}`);
    } catch (errors) {
      notifyError("Document failed to upload", { errors });
    }
  }

  return (
    <UploadWindow
      title="Upload tax forms"
      onClose={onClose}
      onSubmit={onUpload}
      loading={fileUploadStatus === BaseStatus.LOADING}
      visible={visible}
      maxSize={MAX_FILE_SIZE}
    >
      <Text padded align="center">
        <p>
          <a href={TaxFormCSVTemplate}>Download Tax Form CSV Template</a>
          <Text type="secondary" size="small">
            <Icon type="circle-info" left color="blue" />
            Updated on March 5, 2025
          </Text>
        </p>
        <p>Please download the sample CSV file and re-upload with all the tax form information you wish to add.</p>
        Maximum upload file size: {MAX_FILE_SIZE} MB.
      </Text>
    </UploadWindow>
  );
}
