import BigNumber from "bignumber.js";
import { useEffect } from "react";
import { Onboarding, loadOnboarding } from "store/actions/onboarding";
import { MerchantSettings, isMerchantApproved } from "store/hooks/merchantSettings";
import { useShallowSelector } from ".";
import { CountryCode, OnboardingType } from "@trolley/common-frontend";

export function useOnboarding() {
  useEffect(() => {
    loadOnboarding();
  }, []);

  return useShallowSelector((state) => {
    if (state.onboarding.fetchStatus.data === undefined) {
      loadOnboarding();
    }

    const onboarding = state.onboarding.entities.data;

    return {
      bankingEligibility: getBankingOnboardingEligibility(onboarding, state.merchantSettings.entities.data),
      data: onboarding,
      status: state.onboarding.fetchStatus.data,
      error: state.onboarding.errors.data,
    };
  });
}

/**
 *
 * Estimated ACV = ( PPM + FX_MARGIN ) x 12
 * where FX_MARGIN is [ TPV x International_Payout_% x 2% ]
 * @param onboarding The Merchant Onboarding information
 * @returns estimated ACV number
 */
function getEstimatedACV(onboarding: Onboarding | undefined): number {
  if (onboarding) {
    const { businessPpm, businessTotalMonthly, businessIntlPercentage } = onboarding;

    const ppm = businessPpm?.split("-")[0] || "0";
    const fxMargin = new BigNumber(businessTotalMonthly?.split("-")[0] || "0")
      .times(businessIntlPercentage || "0") // 0 - 100
      .dividedBy(100)
      .times(0.02); // 2% FX margin

    return fxMargin.plus(ppm).times(12).toNumber();
  }

  return 0;
}

/**
 * getBankingEligibility
 * IF (merchant.country == US && US is not the only payout country && merchant.acv < 10,000)
 *    Hide all bank transfer onboarding links
 * ELSE
 *    Show bank transfer onboarding page links
 * @return boolean
 */
export function getBankingOnboardingEligibility(onboarding: Onboarding | undefined, merchantSettings: MerchantSettings | undefined): boolean {
  if (!onboarding || !merchantSettings) {
    return false;
  }

  const estimatedACV = getEstimatedACV(onboarding);

  const isUSTheOnlyPayoutCountry = onboarding.expectedPayoutCountries.length === 1 && onboarding.expectedPayoutCountries[0] === CountryCode.US;

  return (
    merchantSettings.onboardingType === OnboardingType.HEAVY &&
    !merchantSettings.sandbox &&
    !!onboarding?.businessCountry &&
    isMerchantApproved(merchantSettings) &&
    (onboarding.businessCountry !== CountryCode.US || isUSTheOnlyPayoutCountry || estimatedACV >= 10000)
  );
}
